import React, { useState } from "react"
import Layout from "../components/layout"
import Meta from "../components/meta"
import Hero from "../components/hero"

export default () => {
  const [isChecked, setIsChecked] = useState(false)
  const toggleCheckbox = () => {
    setIsChecked(!isChecked)
  }
  return (
    <Layout>
      <Meta title="お問い合わせ" />
      <Hero title="お問い合わせ" />
      <div className="max-w-3xl mx-auto px-2">
        <div className="mb-10">
          <h2 className="w-full text-2xl font-bold leading-tight my-5">
            電話でのお問い合わせ
          </h2>
          <p className="text-gray-800 mb-3">
            下記の番号にお電話ください。
            <br />
            営業時間は月~土曜の10:00-19:00時です。
          </p>
          <a
            href="tel:0455943606"
            className="block text-white bg-red-600 font-bold rounded px-4 py-3"
            style={{ width: "fit-content" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-phone inline-block mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#fff"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
            </svg>
            045-594-3606
          </a>
        </div>
        <div className="mb-10">
          <h2 className="w-full text-2xl font-bold leading-tight my-5">
            メールでのお問い合わせ
          </h2>
          <form
            id="contact-me"
            className="w-full mx-auto max-w-3xl text-gray-700 "
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/thanks/"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input name="bot-field" className="hidden" />
            <div className="flex flex-wrap mb-6">
              <div className="relative w-full appearance-none label-floating">
                <input
                  className="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="お名前"
                  required
                />
                <label
                  htmlFor="name"
                  className="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text"
                >
                  お名前
                </label>
              </div>
            </div>
            <div className="flex flex-wrap mb-6">
              <div className="relative w-full appearance-none label-floating">
                <input
                  className="tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="text"
                  name="email"
                  placeholder="メールアドレス"
                  required
                />
                <label
                  htmlFor="email"
                  className="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text"
                >
                  メールアドレス
                </label>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="relative w-full appearance-none label-floating">
                <textarea
                  className="autoexpand tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                  id="message"
                  type="text"
                  placeholder="お問い合わせ内容"
                  defaultValue={""}
                  name="content"
                  required
                />
                <label
                  htmlFor="message"
                  className="absolute tracking-wide py-2 px-4 mb-4 opacity-0 leading-tight block top-0 left-0 cursor-text"
                >
                  お問い合わせ内容
                </label>
              </div>
            </div>
            <div className="mb-4">
              <h3>営業目的手数料について</h3>
              <p className="text-xs mb-2">
                「特定電子メールの送信の適正化等に関する法律」第3条3項の規定に基づき、広告宣伝メールを拒絶する旨をここに明示します。営業目的のお知らせの場合、処理手数料(税込11,000円)を請求させていただきます。
              </p>
              <input
                type="checkbox"
                name="refuse"
                id="refuse"
                onChange={() => toggleCheckbox()}
                className="mr-2"
              />
              <label htmlFor="refuse">同意する</label>
            </div>
            <div>
              <button
                className="w-full shadow bg-red-600 hover:bg-red-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
                disabled={!isChecked}
              >
                送信
              </button>
            </div>
          </form>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "button:disabled { opacity: 0.5; } .label-floating input:not(:placeholder-shown),  .label-floating textarea:not(:placeholder-shown) {padding-top: 1.4rem; }  .label-floating input:not(:placeholder-shown) ~ label, .label-floating textarea:not(:placeholder-shown) ~ label {font-size: 0.8rem; transition: all 0.2s ease-in-out; color: #a0aec0; opacity: 1;  }",
        }}
      />
    </Layout>
  )
}
